/* global LazyLoad */
'use strict';

const utils = require('../components/utils');
const debounce = require('lodash/debounce');
const breakpoints = require('../components/breakpoints');
const keyboardAccessibility = require('../components/keyboardAccessibility');
const refinementBar = require('./refinementBar');

// Store the last loaded query string
let queryValue = '';
const $refineBar = $('.refinement-bar');
const $siteHeader = $('.siteheader');
const $productGrid = $('.product-grid');

let $drawerRefinement;

// moveable grid banner
const $moveableContainerXS = $('.js-moveable-container-xs');
const $moveableContainerLG = $('.js-moveable-container-lg');
const $moveableContainerXL = $('.js-moveable-container-xl');

/**
 * Check the all query parameter in href
 * @param {string} hrefValue - url href value
 * @return {boolean} isAll
 */
function isShowAll(hrefValue) {
    var isAll = false;
    if (hrefValue && hrefValue.indexOf('?') > -1) {
        var results = new RegExp('[\\?&]all=([^&#]*)').exec(hrefValue);
        if (results && results.length > 1 && results[1] === 'true') {
            isAll = true;
        }
    }
    return isAll;
}

/**
 * Check the na query parameter in href
 * @return {boolean} na
 */
function isNotAvailable() {
    var na = false;
    if (window.location.href.indexOf('?') > -1) {
        var results = new RegExp('[\\?&]na=([^&#]*)').exec(window.location.href);
        if (results && results.length > 1 && results[1] === 'true') {
            na = true;
        }
    }
    return na;
}

/**
 * update the url query parameter
 * @param {string} url - URL if the page being loaded
 * @return {string} - update url
 */
function updateUrl(url) {
    if (isShowAll()) {
        return url + (url.indexOf('?') > -1 ? '&' : '?') + 'all=true';
    }
    return url;
}
/**
 * Update DOM elements with Ajax results
 * @param {Object} response - jQuery DOM element
 * @param {string} selector - DOM element to look up in the $tempDom
 */
function updateRobotsMeta(response, selector) {
    var $tempDom = $('<div>').append($(response));
    var $updates = $tempDom.find(selector);
    if ($updates && $updates.length > 0) {
        var content = $updates.data('index-content');
        if (content) {
            $('meta[name=robots]').attr('content', content);
        }
    }
}

/**
 * Update DOM elements with Ajax results
 * @param {Object} $results - jQuery DOM element
 * @param {string} selector - DOM element to look up in the $results
 * @return {undefined}
 */
function updateDom($results, selector) {
    var $updates = $results.find(selector);
    if ($updates.length) {
        var $elemToUpdate = $(selector);
        if ($elemToUpdate.length) {
            $elemToUpdate.empty().html($updates.html());
        }
    }
}

/**
 * Sets paging related params like page size and show all.
 * @param {string} url - URL of the page being loaded
 * @param {boolean} showAll - true if showAll button is triggered, else false
 */
function setPagingParamsInBrowserUrlAndHistory(url, showAll) {
    const params = utils.getParamsFromURL(url);
    let start = null;
    let sz = null;
    let query = [];

    if (!showAll) {
        Object.keys(params).forEach(function (prop) {
            if (prop === 'sz') {
                sz = parseInt(params[prop], 10);
            } else if (prop === 'start') {
                start = parseInt(params[prop], 10);
            } else {
                query.push(prop + '=' + params[prop]);
            }
        });

        if (start !== null && sz !== null && isNaN(start) === false && isNaN(sz) === false) {
            var pagesize = start + sz;
            query.push('pagesize=' + pagesize);
        }
    } else if (showAll) {
        Object.keys(params).forEach(function (prop) {
            if (prop !== 'sz' && prop !== 'start' && prop !== 'all') {
                query.push(prop + '=' + params[prop]);
            }
        });

        query.push('all=true');
    }

    queryValue = query.join('&');

    if (queryValue) {
        window.history.replaceState(null, null, '?' + queryValue);
    }
}

/**
 * Update URL Query with last loaded page params
 * @param {string} pos - Clicked product position
 */
function setPositionParamInBrowserUrlAndHistory(pos) {
    let qs = utils.setUrlKeyValue(window.location.href, 'pos', pos); // this adds or replaces the 'pos' key in the query string
    qs = decodeURIComponent(qs);
    window.history.replaceState(undefined, undefined, qs);
}

/**
 * Scroll window to position of the last clicked product.
 *
 * @param {number} pos - Product tile position
 */
function scrollToPosition(pos) {
    if (pos > 0) {
        var $container = $('.search-results');
        var el = $container.find('.js-grid-tile').get(pos);
        var offset = $(el).offset();
        if (offset != null) {
            var top = offset.top - 110; // Height of top nav...

            $('html, body').animate({
                scrollTop: top
            }, 500);
        }
    }
}

/**
 * Test if the tile image is loaded.
 *
 * @param {number} pos - Product tile position
 * @return {boolean} - tile image is loaded
 */
function isTileImageLoaded(pos) {
    var $container = $('.search-results');
    var el = $container.find('.js-grid-tile').get(pos);
    var img = $(el).find('.tile__image');

    if (img !== undefined) {
        var imgobj = img[0];

        if (!imgobj.complete) {
            return false;
        }
        if (typeof imgobj.naturalWidth !== 'undefined' && imgobj.naturalWidth === 0) {
            return false;
        }
    }

    return true;
}

/**
 * Trigger scrolling after tile image is loaded.
 *
 * @param {number} pos - Product tile position
 * @param {number} counter - Number of times this can loop
 */
function scrollWhenLoaded(pos, counter) {
    var iterations = counter - 1;
    if (iterations <= 0) {
        scrollToPosition(pos);
    } else if (isTileImageLoaded(pos) === true) {
        scrollToPosition(pos);
    } else {
        setTimeout(function () {
            scrollWhenLoaded(pos, iterations);
        }, 500);
    }
}

/**
 * Keep refinement panes expanded/collapsed after Ajax refresh
 *
 * @param {Object} $results - jQuery DOM element
 * @param {string} selector - Container to update
 * @return {undefined}
 */
function handleRefinements($results, selector) {
    // Reset default "active" elements returned in $results (ajax response)
    $results.find('.refinement.active').removeClass('active');

    // "Copy" the actual active refinements from the DOM...
    $('.refinement.active').each(function () {
        this.classList.remove('active');
        $results
            .find(`.${[...this.classList].join('.')}`)
            .addClass('active');
    });

    updateDom($results, selector);
    $('.js-refinements').trigger('search:refinementsupdate');
}

/**
 * Keep size filter values expanded/collapsed after Ajax refresh
 *
 * @param {Object} $results - jQuery DOM element
 * @param {string} selector - Container to update
 * @return {undefined}
 */
function handleSizeFilterRefresh($results, selector) {
    var $target = $('.refinement-size.filter-showMore');
    if ($target.hasClass('filter-showMore--expanded')) {
        $results.find(selector).addClass('filter-showMore filter-showMore--expanded');
    }
    updateDom($results, selector);
    $('.js-refinements').trigger('search:refinementsupdate');
}

/**
 * Hides filter values beyond a given threshold
 *
 * @param {Object} $chips - jQuery DOM element
 * @param {number} numOfChips - Threshold for values to show/hide
 * @return {undefined}
 */
function hideChips($chips, numOfChips) {
    $chips.each(function (index) {
        if (index >= numOfChips) {
            $(this).addClass('d-none');
        }
    });
}

/**
 * Shows all filter values
 *
 * @param {Object} $chips - jQuery DOM element
 * @return {undefined}
 */
function showChips($chips) {
    $chips.removeClass('d-none');
}

/**
 * Toggles show all and hide functionality for filter values
 *
 * @param {Object} $selector - jQuery DOM element
 * @param {Object} $chips - jQuery DOM element
 * @param {number} numOfChips - Threshold for values to show/hide
 * @return {undefined}
 */
function toggleChips($selector, $chips, numOfChips) {
    if ($selector.hasClass('filter-showMore--expanded')) {
        $selector.removeClass('filter-showMore--expanded');
        hideChips($chips, numOfChips);
    } else {
        $selector.addClass('filter-showMore--expanded');
        showChips($chips);
    }
    $('.js-refinements .refinement .title').trigger('filter:click');
}

/**
 * Scroll to Top of Product Grid
 */
const scrollToGridTop = function () {
    const $productSearchResults = $('#product-search-results');
    const $header = $('header');

    const currentScrollTop = $(document).scrollTop();

    // Since the header is sticky, it doesn't count towards scroll so subtract it from the scroll top
    const resultsScrollTop = $productSearchResults.offset().top - $header.height();

    // Only move the scroll position if the user has scrolled down the search results
    if (currentScrollTop > resultsScrollTop) {
        $(document).scrollTop(resultsScrollTop);
    }
};

/**
 * Initialize the show more/less values on size refinement
 *
 * @return {undefined}
 */
function initSizeFilterShowMore() {
    var $selector = $('.refinement-bar .refinement-size');
    var $chips = $selector.find('li');

    var chipsToShowNum = 0;
    var containerWidth = $selector.width();
    var chipWidth = $chips.outerWidth(true);

    if (containerWidth && chipWidth) {
        chipsToShowNum = parseInt(containerWidth / chipWidth, 10) * 4;
    }

    if (chipsToShowNum && $chips.length > chipsToShowNum) {
        // fire click event on enter
        keyboardAccessibility('.js-filter-showMore', {
            13: function (filter) { // enter
                $(filter).click();
            }
        },
        function () {
            return this; // this is the scope of the jQuery event handler in keyboardAccessibility
        }, 'body');

        $('.js-filter-showMore')
            .off('click')
            .on('click', toggleChips.bind(null, $selector, $chips, chipsToShowNum))
            .removeClass('d-none');
        if ($selector.hasClass('filter-showMore filter-showMore--expanded')) {
            showChips($chips);
        } else {
            $selector.addClass('filter-showMore');
            hideChips($chips, chipsToShowNum);
        }
    }
}

/**
 * Parse Ajax results and updated select DOM elements
 *
 * @param {string} response - Ajax response HTML code
 * @return {undefined}
 */
function parseResults(response) {
    const $results = $(response);
    updateRobotsMeta(response, '.search-results');
    let specialHandlers = {
        '.refinement-bar .refinement-size': handleSizeFilterRefresh,
        '.refinement-bar .refinements--color-size-price': handleRefinements
    };
    let resultsCount = 0;
    let displayedCount = 0;
    let $btnShowMore = {};
    let $btnShowAll = {};

    // Update DOM elements that do not require special handling
    [
        '.grid-header',
        '.filter-status__bar',
        '.header.page-title',
        '.product-grid',
        '.show-more',
        '.filter-status--applied',
        '.js-refinement-category',
        '.js-result-count',
        '.refinement-apply',
        '.filter-status__clear'
    ].forEach(function (selector) {
        updateDom($results, selector);
    });

    Object.keys(specialHandlers).forEach(function (selector) {
        specialHandlers[selector]($results, selector);
    });

    // Hide/show "More" button depending on how many products were returned.
    // TODO: refactor.
    resultsCount = $('.js-result-count').data('count') || resultsCount;
    displayedCount = $('.js-grid-tile').length;
    $btnShowMore = $('.show-more .show-more-button');
    $btnShowAll = $('.show-more .show-all-button');

    if (resultsCount > displayedCount) {
        $btnShowMore.removeClass('d-none');
        $btnShowAll.removeClass('d-none');
    } else {
        $btnShowMore.addClass('d-none');
        $btnShowAll.addClass('d-none');
    }

    $('.js-refinements').trigger('search:pageupdate');

    updateSortOptions(response); // eslint-disable-line no-use-before-define
    initSizeFilterShowMore();
}

/**
 * Update selected sort option label
 * @param {string} selectedOption - Ajax response HTML code
 */
function updateSortByLabel(selectedOption) {
    if (selectedOption) {
        var $sortByDropdown = $('.js-sort-by-dropdown');
        var defaultLabel = $sortByDropdown.data('sort-label');
        var $activeOptionItem = $('.' + selectedOption);
        $sortByDropdown.find('.sort-order__text').text(defaultLabel + ': ' + $activeOptionItem.text().trim());
    }
}

/**
 * Reinitialize LazyLoad
 */
function reinitLazyLoad() {
    var lazyLoadInstance = new LazyLoad({
        elements_selector: '.lazy',
        use_native: true,
        load_delay: 200
    });
    // Update the instance
    if (lazyLoadInstance) {
        lazyLoadInstance.update();
    }
}

/**
 * Replace product grid HTML with updated code on Sort
 *
 * @param {string} response - Updated HTML code
 * @return {undefined}
 */
function updateProductGrid(response) {
    updateRobotsMeta(response, '.product-grid-footer');

    $('.product-grid').html(response); // Reference to $productGrid is lost on grid update

    $('.js-refinements').trigger('search:pageupdate');
    $(document).trigger('search:productGridUpdated');

    $.spinner().stop();

    reinitLazyLoad();
}

/**
 * Click event handler for sort dropdown.
 */
function initSortDropdown() {
    const $menuOptions = $('#sort-menu .form-control__options__item');

    // Sync sort menu label with selected item
    if ($menuOptions.hasClass('selected')) {
        updateSortByLabel($('#sort-menu .selected').data('id'));
    }

    // Handle sort order menu selection
    $menuOptions.off('click').on('click', function (e) {
        e.preventDefault();
        var $clickedItem = $(this);

        var url = $clickedItem.data('sorturl');
        var optionId = $clickedItem.data('id');
        setPagingParamsInBrowserUrlAndHistory(url);

        $.spinner().start();
        $menuOptions.removeClass('selected');
        $clickedItem.addClass('selected');

        $clickedItem.trigger('search:sort', url);
        updateSortByLabel(optionId);
        $.ajax({
            url: updateUrl(url),
            data: { selectedUrl: url },
            method: 'GET',
            success: function (response) {
                updateProductGrid(response);
                $('#sort-menu').collapse('hide');
                scrollToGridTop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });
}


/**
 * Update sort option URLs from Ajax response
 *
 * @param {string} response - Ajax response HTML code
 * @return {undefined}
 */
function updateSortOptions(response) {
    var $tempDom = $('<div>').append($(response));
    var sortOptions = $tempDom.find('.product-grid-footer').data('sort-options').options;
    sortOptions.forEach(function (option) {
        $('#sort-menu .dropdown-item.' + option.id).data('sorturl', option.url);
    });
    initSortDropdown();
    const params = utils.getParamsFromURL(window.location.href);
    if (params.srule) {
        updateSortByLabel(params.srule);
    }
}

/**
 * Append results to product grid on Show More or Show All
 *
 * @param {string} response - Updated HTML code
 * @return {undefined}
 */
function appendProductGrid(response) {
    updateRobotsMeta(response, '.product-grid-footer');
    updateSortOptions(response);

    // Remove More and Show All buttons.
    $('.product-grid .product-grid-footer').remove();

    // Append after existing tiles. Check the last tile is not in the banner GSD-4530
    $('.product-grid .js-grid-tile, .product-grid .js-inline-tile').filter(function () {
        return !$(this).closest('.grid-banner').length;
    }).last().after(response);

    $('.js-refinements').trigger('search:pageupdate');

    reinitLazyLoad();
}

/**
 * Method to trigger show more products
 * @param {Object} $node current action node
 * @param {Object} e event object
 */
function triggerShowMore($node) {
    var url = $node.data('url');
    var hrefVal = $node.attr('href') || $node.data('href');
    var showAll = isShowAll(hrefVal);

    setPagingParamsInBrowserUrlAndHistory(hrefVal, showAll);

    $node.trigger('search:showMore');
    // hide Show More and Show All button if user clicks Show All action button
    if ($node.hasClass('show-all-button')) {
        $node.removeClass('d-inline-block');
        $node.addClass('d-none');
        $('.show-more-button').addClass('d-none');
    }

    $('.loading-dots').removeClass('d-none');

    $.ajax({
        url: url,
        data: { selectedUrl: url },
        method: 'GET',
        success: function (response) {
            appendProductGrid(response);
        },
        complete: function () {
            $('.loading-dots').addClass('d-none');
            $('.showAllStatus').val('complete');
            $(document).trigger('search:productGridUpdated');
        }
    });
}
/**
 * Initialize Show More and Show All event listeners
 */
function initializeShowMore() {
    // Show more products
    $('.search-results .show-more-button, .search-results .show-all-button').off('click').on('click', function (e) {
        e.preventDefault();
        e.stopPropagation();

        triggerShowMore($(this));
    });
}

/**
 * Fixed applied filters on mobile
 */
function stickyFilterHeader() {
    if (breakpoints.isHighRes()) {
        return;
    }

    var filtersHeaderHeight = $('.filter-status').outerHeight();
    $('.filter-refinements').css('top', filtersHeaderHeight);

    var countfilters = $('.filter-status--applied ul').children().length;
    if (countfilters > 0) {
        $('.filter-status__bar').addClass('active');
    } else {
        $('.filter-status__bar').removeClass('active');
    }
}

/**
 * Update Refinement bar height for overflow scrolling on mobile
 */
function updateRefinementBarHeight() {
    // update the refinementbar height on mobile
    const $refinementBar = $('.refinement-bar');
    const $filterRefinements = $refinementBar.find('.drawer__content');
    const filtersHeaderHeight = $('.filter-status').outerHeight() || 0;
    const refinementApplyHeight = $('.refinement-apply').outerHeight() + filtersHeaderHeight;

    $filterRefinements.css('height', $refinementBar.outerHeight() - refinementApplyHeight);
}

/**
 * @returns {Object} query Parameter from url
 */
function getUrlVars() {
    var getUrlParams = {};
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
        getUrlParams[key] = value;
    });
    return getUrlParams;
}

/**
 * Initialize events on PLP refresh.
 */
function initializeOnRefresh() {
    $(function () {
        var isShowAllExist = getUrlVars().all;
        var scrollPos = getUrlVars().spos;
        if (isShowAllExist && scrollPos) {
            $('body, html').animate({
                scrollTop: scrollPos
            }, 800);
        }
    });
    initializeShowMore();
    reinitLazyLoad();
}

/**
 * If deferredProductsAsyncUrl is on the page and has a value, we need
 * to make a request immediately to get the rest of the products from the original
 * search call
 */
function getLazyLoadedProducts() {
    let url = $('.js-deferred-products-async-url').val();
    let $loadingDots = $('.loading-dots');
    if (url) {
        $loadingDots.removeClass('d-none');
        $.ajax({
            url: url,
            data: { selectedUrl: url },
            method: 'GET',
            success: function (response) {
                updateRobotsMeta(response, '.product-grid-footer');
                updateSortOptions(response);

                // Remove More and Show All buttons.
                $('.product-grid .product-grid-footer').remove();

                // Append after existing tiles. Check the last tile is not in the banner GSD-4530
                $('.product-grid .js-grid-tile, .product-grid .js-inline-tile').filter(function () {
                    return !$(this).closest('.grid-banner').length;
                }).last().after(response);
                reinitLazyLoad();
                $('.search-results .show-more-button').removeClass('d-none');
                $('.search-results .show-all-button').removeClass('d-none');
                initializeShowMore();
                // event for getting the additional lazy loaded products page results
                $productGrid.trigger('search:getLazyLoadedProducts');
                $(document).trigger('search:productGridUpdated');
            },
            complete: function () {
                $loadingDots.addClass('d-none');
            }
        });
    }
}

/**
 * Scroll to a specific product based upon position
 */
function scrollToProduct() {
    let url = $('.js-updategridurl').data('updategridurl');

    if (!url) {
        return;
    }

    const params = utils.getParamsFromURL(window.location.href);

    if (!params || !params.pos) {
        return;
    }

    var pos = parseInt(params.pos, 10);

    // scrollTo position on multi page results after lazy load
    $productGrid.on('search:getLazyLoadedProducts', function () {
        scrollWhenLoaded(pos, 10);
        $siteHeader.trigger('height-changed');
    });
}

/**
 * Injects full-width banner into grid
 */
function injectBannerContent() {
    const $contentContainer = $('.js-moveable-slot');

    if ($contentContainer.length) {
        const currentBreakpoint = breakpoints.getCurrentBreakpoint();
        let $destination;

        switch (currentBreakpoint) {
            case 'lg':
                $destination = $moveableContainerLG;
                break;
            case 'xl':
                $destination = $moveableContainerXL;
                break;
            default:
                $destination = $moveableContainerXS;
        }

        // move and unhide content slot
        $contentContainer.appendTo($destination).removeClass('d-none');
    }
}

module.exports = {
    initRefinements: function () {
        $('.js-refinements').on('search:pageupdate', initializeOnRefresh);
        initSizeFilterShowMore();
    },

    initBannerContent: function () {
        if (!$('.js-moveable-slot').length) return;

        injectBannerContent();

        let lastBreakpoint = breakpoints.getCurrentBreakpoint();
        let newBreakpoint;

        window.addEventListener('resize', debounce(function () {
            newBreakpoint = breakpoints.getCurrentBreakpoint();

            if (lastBreakpoint !== newBreakpoint) {
                lastBreakpoint = newBreakpoint;
                injectBannerContent();
            }
        }, 25), { passive: true });
    },

    initShowUnavailable: function () {
        $('.js-show-unavailable').on('click', function () {
            const unavailableParam = $(this).data('unavailableparam');

            if (unavailableParam) {
                const url = new URL(window.location.href);
                const [paramName, paramValue] = unavailableParam.split('=');
                const updatedParams = url.searchParams;
                updatedParams.set(paramName, paramValue);
                const updatedUrl = new URL(`${url.origin}${url.pathname}?${updatedParams}`);

                window.location = updatedUrl;
            }
        });
    },

    triggerLazyLoad: getLazyLoadedProducts,

    openFilters: function () {
        // Display refinements bar when Menu icon clicked
        $('.search-results').on('click', '.js-filter-results', function () {
            // Quick filters enabled (drawer component version)
            if ($refineBar.length) {
                if ($drawerRefinement) {
                    $drawerRefinement.open();
                } else {
                    $drawerRefinement = $.drawer({
                        target: '.refinement-bar'
                    });
                }
                updateRefinementBarHeight();
            }
        });
    },

    closedFilters: function () {
        // On mobile all filters should be collapsed
        if (breakpoints.isLowRes()) {
            $('.card.collapsible-xl.refinement').removeClass('active');
        }
    },

    closeRefinements: function () {
        // Refinements close button
        $('body').on('click', '.refinement-bar button.close, .backdrop, .btn-apply-filters', function () {
            if ($drawerRefinement) {
                $drawerRefinement.close();
            } else {
                $('.refinement-bar').removeClass('open js-popover-shown');
                utils.removeBackdrop();
                $.enableScroll();
            }
        });
    },

    quickFilters: function () {
        // close other quick filters when quick filter drawer is opened
        $(document).on('click', '.js-quick-filters .card-header', function (e) {
            const $selectedCard = $(e.target).parent('.card');
            $selectedCard.addClass('selected');
            $('.js-quick-filters .card.active:not(.selected)').removeClass('active');
            $selectedCard.removeClass('selected');
        });

        // close quick filter drawer when filter is selected
        $(document).on('click', '.js-quick-filters [data-refinementurl]', function (e) {
            const $selectedCard = $(e.target).closest('.card');
            $selectedCard.removeClass('active');
        });

        // close quick filter drawer if clicked elsewhere
        $(document).on('click', '*', function (e) {
            const isClickedCard = $(e.target).closest('.card').length;

            if (!isClickedCard) {
                $('.js-quick-filters .card.active').removeClass('active');
            }
        });
    },

    resize: function () {
        // Close refinement bar and close the backdrop if user resizes browser
        $(window).on('widthResize.reloadEvents', function () {
            if (breakpoints.isHighRes()) {
                $refineBar.removeClass('open');
                utils.removeBackdrop();
            } else {
                $.enableScroll();
                $refineBar.removeAttr('style');
            }
        });
    },

    sort: function () {
        initSortDropdown();
    },

    applyFilter: function () {
        // fire click event on enter
        keyboardAccessibility('.js-refinements .form-check input', {
            13: function (input) { // enter
                $(input).trigger('click');
            }
        },
        function () {
            return this; // this is the scope of the jQuery event handler in keyboardAccessibility
        }, 'body');

        // Handle refinement value selection and reset click
        $('.search-results, .refinement-bar').on('click', '[data-refinementurl]', function (e) {
            e.preventDefault();

            let targetUrl = $(this).data('refinementurl');
            window.history.replaceState(null, null, targetUrl);
            setPagingParamsInBrowserUrlAndHistory(targetUrl);
            targetUrl = utils.appendParamToURL(targetUrl, 'format', 'ajax');

            let $spinner = breakpoints.isLowRes() ? $('.refinement-bar').spinner() : $.spinner();
            $spinner.start();

            $.ajax({
                url: updateUrl(targetUrl),
                data: {
                    page: $('.product-grid-footer').data('page-number'),
                    selectedUrl: targetUrl
                },
                method: 'GET'
            }).done((response) => {
                parseResults(response);
                scrollToGridTop();
                getLazyLoadedProducts();
                updateRefinementBarHeight();

                if (!$drawerRefinement) {
                    refinementBar.destroyStickyPLPComponents();
                    stickyFilterHeader();
                    refinementBar.initStickyPLPComponents();
                }
            }).always(() => {
                $spinner.stop();
            });

            $(this).trigger('search:filter', e);
        });
    },

    firstLoad: function () {
        initializeOnRefresh();
        refinementBar.initStickyPLPComponents();

        $('body').on('click', '.js-grid-tile .tile__image-container a, .js-grid-tile .js-tile-link, .js-grid-tile .js-tile-ratings, .js-grid-tile button.js-quickview', function () {
            const $link = $(this);

            // Skipping position update for product tile inside compare modal
            if (!$link.closest('.js-compare-modal').length) {
                const index = $link.parents('.js-grid-tile').index('.js-grid-tile');
                setPositionParamInBrowserUrlAndHistory(index);
            }
        });

        $(function () {
            if (isNotAvailable()) {
                var url = window.location.href.split('?')[0];
                const params = utils.getParamsFromURL(window.location.href);
                let query = [];
                Object.keys(params).forEach(function (prop) {
                    if (prop !== 'na') {
                        query.push(prop + '=' + params[prop]);
                    }
                });
                let str = query.join('&');
                window.history.pushState({}, 'Back to Parent Category', url + '?' + str);
            }

            scrollToProduct();
        });
    },
    onLoadedDrawerRecommendations: function () {
        $('body').on('contentAsyncLoaded.drawer', '.drawer--tile-recommendations', function () {
            reinitLazyLoad();
        });
    }
};
